import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import './SuccessPage.css';

const SuccessPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const paymentInProgress = sessionStorage.getItem('paymentInProgress');

    // Redirect to home if accessed directly
    if (!paymentInProgress) {
      navigate('/');
    }

    // Clear session storage after rendering
    sessionStorage.removeItem('paymentInProgress');
  }, [navigate]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Payment Successful - Royol Technology</title>
        <meta
          name="description"
          content="Payment successful! Thank you for choosing Royol Technology. We will get in touch with you soon."
        />
      </Helmet>
      <section className="success-section">
        <div className="success-container">
          <h1>Payment Successful!</h1>
          <p>Thank you for your payment. We will get in touch with you soon.</p>
          <button onClick={() => navigate('/')}>Go to Home</button>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default SuccessPage;
