import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async'; // Use HelmetProvider for context
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Pricing.css';

const Pricing = () => {
  const [activeIndex, setActiveIndex] = useState(0); // Track active card index

  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once while scrolling
    });
  }, []);

  // Scroll to a specific card when a dot is clicked
  const handleDotClick = (index) => {
    const carousel = document.querySelector('.pricing-table');
    const cardWidth = carousel.children[0].offsetWidth;
    carousel.scrollTo({
      left: index * cardWidth,
      behavior: 'smooth',
    });
    setActiveIndex(index);
  };

  // Update active dot on scroll
  useEffect(() => {
    const carousel = document.querySelector('.pricing-table');
    const handleScroll = () => {
      const cardWidth = carousel.children[0].offsetWidth;
      const newIndex = Math.round(carousel.scrollLeft / cardWidth);
      setActiveIndex(newIndex);
    };
    carousel.addEventListener('scroll', handleScroll);
    return () => carousel.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Affordable Pricing Plans for Web and App Development | Royol Technology</title>
        <meta
          name="description"
          content="Explore our pricing plans for professional web and app development. Choose from Starter, Professional, or Enterprise solutions tailored to your needs."
        />
      </Helmet>
      <section className="pricing-section" id="pricing-section">
        {/* Visible Title */}
        <h1 className="pricing-section-title">Affordable Pricing Plans for Web and App Development</h1>
        <p className="section-subtitle">
          Choose the perfect plan for your needs—whether you're starting small or scaling big.
        </p>
        <div className="pricing-table">
          {/* Basic Plan */}
          <div className="pricing-card" data-aos="fade-up">
            <h2>Starter Basic Website</h2>
            <p className="price" id="price-basic">
              Starting at <span id="total-price-basic">$499.99</span>
            </p>
            <p>
              A small website with 1 to 3 pages, including a homepage, about page,
              and contact page. May include basic features like a contact form.
            </p>
            <p>Perfect for personal blogs and small businesses</p>
            <ul>
              <li>Mobile-responsive design</li>
              <li>Basic SEO setup</li>
              <li>Free domain consultation</li>
            </ul>
            <p>Delivery time: 1-2 weeks</p>
          </div>

          {/* Standard Plan */}
          <div className="pricing-card" data-aos="fade-up" data-aos-delay="200">
            <h2>Professional Website</h2>
            <p className="price" id="price-pro">
              Starting at <span id="total-price-pro">$999.99</span>
            </p>
            <p>
              A medium-sized website with 1 to 5 pages, including additional pages
              such as services or product listings. Includes more design customization.
            </p>
            <p>Ideal for businesses needing more functionality</p>
            <ul>
              <li>Custom design and development</li>
              <li>E-commerce capabilities</li>
              <li>Advanced SEO</li>
              <li>Blog integration (optional)</li>
              <li>Google Analytics setup</li>
            </ul>
            <p>Delivery time: 2-3 weeks</p>
          </div>

          {/* Enterprise Plan */}
          <div className="pricing-card" data-aos="fade-up" data-aos-delay="400">
            <h2>Enterprise Solutions</h2>
            <p className="price" id="price-enterprise">
              Starting at <span id="total-price-enterprise">$9999.99</span>
            </p>
            <p>
              A larger website with 1 to 7 pages, offering more content and
              additional features like a blog, portfolio, or e-commerce integration.
            </p>
            <p>
              For large-scale business needs, high-end web design and development,
              advanced e-commerce, custom integrations.
            </p>
            <ul>
              <li>Custom design and development</li>
              <li>Advanced SEO and speed optimization</li>
              <li>API and third-party integrations</li>
              <li>Advanced analytics dashboard</li>
              <li>Maintenance and support for 3 months</li>
            </ul>
            <p>Delivery time: 4-6 weeks</p>
          </div>

          {/* Basic Online Presence Plan */}
          <div className="pricing-card" data-aos="fade-up" data-aos-delay="600">
            <h2>Basic Online Presence Plan</h2>
            <p className="price" id="price-online-presence">
              Starting at <span id="total-price-online-presence">$199.99</span>
            </p>
            <p>
              A single-page website with a modern design.
            </p>
            <p>Perfect for freelancers, portfolios, or event-based needs.</p>
            <ul>
              <li>Responsive single-page design</li>
              <li>Social media profile integration</li>
            </ul>
            <p>Delivery time: 3-5 days</p>
          </div>

          {/* Premium Enterprise Plan */}
          <div className="pricing-card" data-aos="fade-up" data-aos-delay="800">
            <h2>Premium Enterprise Plan</h2>
            <p className="price" id="price-premium-enterprise">
              Custom Quote
            </p>
            <p>
              Tailored solutions for enterprises or large organizations.
            </p>
            <ul>
              <li>Full-stack app development alongside the website</li>
              <li>Custom CRM or ERP integrations</li>
              <li>Multi-language support</li>
              <li>Dedicated account manager</li>
            </ul>
            <p>Delivery time: 6-12 weeks</p>
          </div>
        </div>
        {/* Scroll Dots */}
        <div className="scroll-indicators">
          {[0, 1, 2].map((_, index) => (
            <div
              key={index}
              className={`dot ${activeIndex === index ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
            ></div>
          ))}
        </div>
      </section>
    </HelmetProvider>
  );
};

export default Pricing;