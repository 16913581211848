import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import emailjs from 'emailjs-com';
import './ContactForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Email template data for sending to you (the owner)
    const toOwnerParams = {
      to_name: 'Royol Technology',
      from_name: formData.name,
      from_email: formData.email,
      subject: formData.subject,
      message: `
        Message: ${formData.message}
        From: ${formData.name} (${formData.email})
      `,
    };

    // Email template data for sending to the user
    const toUserParams = {
      to_name: formData.name,
      user_email: formData.email,
      user_message: `We have received your message: "${formData.message}". Thank you for contacting us!`,
    };

    // Send email to you (the owner)
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        toOwnerParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        () => {
          // Send confirmation email to the user
          emailjs
            .send(
              process.env.REACT_APP_EMAILJS_SERVICE_ID,
              process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
              toUserParams,
              process.env.REACT_APP_EMAILJS_USER_ID
            )
            .then(() => {
              setIsSubmitted(true);
              setTimeout(() => {
                setIsSubmitted(false);
                window.location.href = '/'; // Redirect to home page
              }, 3000);
            })
            .catch((error) => {
              console.error('Error sending confirmation email:', error);
              alert('Failed to send confirmation email. Please try again.');
            });
        },
        (error) => {
          console.error('Error sending email to owner:', error);
          alert('Failed to send your message. Please try again.');
        }
      );
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Contact Us - Royol Technology</title>
        <meta
          name="description"
          content="Have questions or need assistance? Contact Royol Technology today using our form."
        />
      </Helmet>
      <section id="contact-section">
        <div className="contact-container">
          <h2 className="contact-title">Contact Us</h2>
          <p className="contact-subtitle">
            Have questions or need assistance? Fill out the form below and we’ll get back to you as
            soon as possible.
          </p>
          <form className="contact-form" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <button type="submit">Send Message</button>
          </form>
          {isSubmitted && (
            <p className="success-message">Thank you! Your message has been sent.</p>
          )}
        </div>
      </section>
    </HelmetProvider>
  );
};

export default ContactForm;