import React, { useRef, useEffect } from 'react';
import './TrustedCompanies.css';

function TrustedCompanies() {
  const carouselRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (carouselRef.current) {
        const scrollAmount = carouselRef.current.offsetWidth;
        carouselRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });

        // Reset scroll position for infinite scrolling
        if (
          carouselRef.current.scrollLeft + carouselRef.current.offsetWidth >=
          carouselRef.current.scrollWidth
        ) {
          carouselRef.current.scrollTo({ left: 0, behavior: 'smooth' });
        }
      }
    }, 3000); // Change logos every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const logos = [
    { id: 1, src: '/logos/logo1.png', alt: 'Company 1' },
    { id: 2, src: '/logos/logo2.png', alt: 'Company 2' },
    { id: 3, src: '/logos/logo3.png', alt: 'Company 3' },
    { id: 4, src: '/logos/logo4.png', alt: 'Company 4' },
    { id: 5, src: '/logos/logo5.png', alt: 'Company 5' },
    { id: 6, src: '/logos/logo6.png', alt: 'Company 6' },
    { id: 7, src: '/logos/logo7.png', alt: 'Company 7' },
    { id: 8, src: '/logos/logo8.png', alt: 'Company 8' },
    { id: 9, src: '/logos/logo9.png', alt: 'Company 9' },
    { id: 10, src: '/logos/logo10.png', alt: 'Company 10' },
  ];

  return React.createElement(
    'div',
    { className: 'trusted-companies' },
    React.createElement('h2', null, 'Trusted by Leading Companies'),
    React.createElement(
      'div',
      { className: 'logo-carousel', ref: carouselRef },
      logos.map((logo) =>
        React.createElement(
          'div',
          { key: logo.id, className: 'logo-item' },
          React.createElement('img', { src: logo.src, alt: logo.alt })
        )
      )
    )
  );
}

export default TrustedCompanies;