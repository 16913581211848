import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import React from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO
import Header from './Header';
import WelcomeSection from './WelcomeSection';
import Pricing from './Pricing';
import MaintenancePlan from './MaintenancePlan.js';
import Portfolio from './Portfolio';
import Testimonials from './Testimonials';
import Quote from './QuoteForm.js';
import SuccessPage from './SuccessPage.js';
import Footer from './Footer';
import CancelPage from './CancelPage.js';
import AppsPage from './AppsPage';  // Import Apps Page
import ContactForm from './ContactForm.js';  // Import Contact Form Page
import ScrollToTop from './ScrollToTop';
import Services from './Services'; // Import Services Page
import TrustedCompanies from './TrustedCompanies';
import NotFound from './NotFound'; // Import 404 Page

// A wrapper to protect routes
const ProtectedRoute = ({ children }) => {
  const location = useLocation();

  // Redirect to the home page if the user didn't come from the payment process
  if (!location.state?.fromPayment) {
    return <Navigate to="/" replace />;
  }

  return children;
};

function App() {
  return (
    <>
      <Helmet>
        {/* Page Meta Information */}
        <title>Royol Technology | Affodable Websites and Innovative Digital Solutions</title>
        <meta
          name="description"
          content="Royol Technology specializes in building modern websites, providing web development, app development, networking, and custom technology solutions for businesses of all sizes."
        />
        <meta
          name="keywords"
          content="web development, website design, building websites, Royol Technology, Widmison Francois, digital solutions, custom technology"
        />
        <meta name="author" content="Widmison Francois" />

        {/* Open Graph Meta */}
        <meta property="og:title" content="Royol Technology | Website Building Experts" />
        <meta
          property="og:description"
          content="Build your dream website with Royol Technology. We offer top-notch web development, app development, and networking solutions for businesses."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://royoltechnology.com/logo.png" />
        <meta property="og:url" content="https://royoltechnology.com" />

        {/* Twitter Meta */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Royol Technology | Building Websites and More" />
        <meta
          name="twitter:description"
          content="Build your website with ease! Royol Technology specializes in web development and custom digital solutions."
        />
        <meta name="twitter:image" content="https://royoltechnology.com/logo.png" />

        {/* Google Tag */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-MWPWZXDV7C"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'G-MWPWZXDV7C');
          `}
        </script>
      </Helmet>

      <ScrollToTop />
      <Header />
      <Routes>
        {/* Home Page */}
        <Route
          path="/"
          element={
            <>
              <WelcomeSection />
              <Services />
              <Pricing />
              <MaintenancePlan />
              <Testimonials />
            </>
          }
        />
        {/* Portfolio Page */}
        <Route path="/portfolio" element={<Portfolio />} />
        {/* Apps Page */}
        <Route path="/apps" element={<AppsPage />} />
        {/* Contact Form Page */}
        <Route path="/contactform" element={<ContactForm />} />
        {/* Quote Form Page */}
        <Route path="/quote" element={<Quote />} />
        {/* Pricing Page */}
        <Route path="/pricing" element={<Pricing />} />
        {/* Maintenance Page */}
        <Route path="/maintenance" element={<MaintenancePlan />} />
        {/* Services Page */}
        <Route path="/services" element={<Services />} />
        {/* Success Page */}
        <Route path="/thank-you" element={<SuccessPage />} />
        {/* Cancel Page */}
        <Route path="/payment-canceled" element={<CancelPage />} />
        {/* 404 Page */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;