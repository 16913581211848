import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import './CancelPage.css';


const CancelPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const paymentInProgress = sessionStorage.getItem('paymentInProgress');

    // Redirect to home if accessed directly
    if (!paymentInProgress) {
      navigate('/');
    }

    // Clear session storage after rendering
    sessionStorage.removeItem('paymentInProgress');
  }, [navigate]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Payment Canceled - Royol Technology</title>
        <meta
          name="description"
          content="Payment canceled. Please try again or contact us for support."
        />
      </Helmet>
      <section className="cancel-section">
        <div className="cancel-container">
          <h1>Payment Canceled!</h1>
          <p>Your payment was not completed. Please try again or contact support.</p>
          <button onClick={() => navigate('/quote')}>Try Again</button>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default CancelPage;
