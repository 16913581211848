import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { loadStripe } from '@stripe/stripe-js';
import './MaintenancePlan.css';

// Load Stripe using the public key from the environment variable
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const MaintenancePlan = () => {
  const [activeDot, setActiveDot] = useState(0);
  const cardCount = 6; // Total number of cards

  // Handle scrolling and update active dot
  const handleScroll = (e) => {
    const container = e.target;
    const cardWidth = container.firstChild.offsetWidth + 20; // Card width + gap
    const index = Math.round(container.scrollLeft / cardWidth);
    setActiveDot(index);
  };

  // Handle Stripe Checkout
  const handleStripeCheckout = async (priceId) => {
    const stripe = await stripePromise;

    const { error } = await stripe.redirectToCheckout({
      lineItems: [{ price: priceId, quantity: 1 }],
      mode: 'subscription',
      successUrl: `${window.location.origin}/success`,
      cancelUrl: `${window.location.origin}/cancel`,
    });

    if (error) {
      console.error('Stripe Checkout Error:', error.message);
    }
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Website Maintenance Plans | Royol Technology</title>
        <meta
          name="description"
          content="Subscribe to our comprehensive website maintenance plans. From individuals to large enterprises, we have a solution tailored for you."
        />
      </Helmet>
      <section className="maintenance-plan-section">
        <h1 className="plan-title">Monthly Website Maintenance Plans</h1>
        <p className="plan-subtitle">
          Keep your website secure, updated, and performing at its best with our tailored monthly plans.
        </p>
        <div className="plan-cards-container" onScroll={handleScroll}>
          {/* Plan Cards */}
          {[
            {
              title: 'Starter Plan',
              price: '$29.99/month',
              stripePriceId: process.env.REACT_APP_STARTER_PLAN_PRICE_ID,
              features: [
                'Monthly updates and backups',
                'Basic performance checks',
                'Email support (24-hour response time)',
              ],
              description: 'Best for personal blogs and small portfolios.',
            },
            {
              title: 'Basic Plan',
              price: '$49.99/month',
              stripePriceId: process.env.REACT_APP_BASIC_PLAN_PRICE_ID,
              features: [
                'Monthly updates and backups',
                'Basic security monitoring',
                'Email support',
                'Bug fixes (limited to 2 requests/month)',
              ],
              description: 'Perfect for personal websites and freelancers.',
            },
            {
              title: 'Professional Plan',
              price: '$99.99/month',
              stripePriceId: process.env.REACT_APP_PROFESSIONAL_PLAN_PRICE_ID,
              features: [
                'Weekly updates and backups',
                'Advanced security monitoring',
                'Email & chat support',
                'Performance optimization',
                'Bug fixes (up to 5 requests/month)',
              ],
              description: 'Ideal for small-to-medium businesses.',
            },
            {
              title: 'Advanced Plan',
              price: '$149.99/month',
              stripePriceId: process.env.REACT_APP_ADVANCED_PLAN_PRICE_ID,
              features: [
                'Daily updates and backups',
                'Comprehensive security monitoring',
                'Priority email & chat support',
                'Performance and SEO optimization',
                'Custom reports (monthly)',
                'Bug fixes (up to 10 requests/month)',
              ],
              description: 'Great for e-commerce websites and growing businesses.',
            },
            {
              title: 'Premium Plan',
              price: '$249.99/month',
              stripePriceId: process.env.REACT_APP_PREMIUM_PLAN_PRICE_ID,
              features: [
                'Daily updates and backups',
                '24/7 security monitoring',
                'Dedicated account manager',
                'Custom analytics dashboard',
                'Unlimited bug fixes',
                'Priority support (phone & chat)',
              ],
              description: 'Perfect for enterprises and mission-critical websites.',
            },
            {
              title: 'Enterprise Plan',
              price: 'Custom Quote',
              features: [
                'Custom updates and integrations',
                'API and third-party service management',
                'Advanced analytics and performance tracking',
                'On-demand development and feature upgrades',
                'Dedicated technical team',
                '24/7 monitoring and SLA agreements',
              ],
              description: 'Best for large-scale businesses and organizations.',
            },
          ].map((plan, index) => (
            <div className="plan-card" key={index}>
              <h2>{plan.title}</h2>
              <p className="plan-price">{plan.price}</p>
              <ul>
                {plan.features.map((feature, idx) => (
                  <li key={idx}>{feature}</li>
                ))}
              </ul>
              <p>{plan.description}</p>
              {plan.price === 'Custom Quote' ? (
                <button
                  className="subscribe-button"
                  onClick={() => window.location.href = '/contactform'}
                >
                  Contact Us
                </button>
              ) : (
                <button
                  className="subscribe-button"
                  onClick={() => handleStripeCheckout(plan.stripePriceId)}
                >
                  Subscribe
                </button>
              )}
            </div>
          ))}
        </div>
        {/* Scroll Indicators */}
        <div className="scroll-indicators">
          {Array.from({ length: cardCount }).map((_, index) => (
            <div
              key={index}
              className={`dot ${activeDot === index ? 'active' : ''}`}
              onClick={() => {
                document.querySelector('.plan-cards-container').scrollTo({
                  left: index * (300 + 20), // Card width + gap
                  behavior: 'smooth',
                });
              }}
            ></div>
          ))}
        </div>
      </section>
    </HelmetProvider>
  );
};

export default MaintenancePlan;