import React from 'react';
import { Helmet } from 'react-helmet';

const AppsPage = () => {
  return (
    <div>
      <Helmet>
        <title>Apps - Royol Technology</title>
        <meta name="description" content="Explore the apps developed by Royol Technology, providing solutions for businesses and individuals." />
        <meta name="keywords" content="apps, Royol Technology, software development, business solutions" />
      </Helmet>
      <h1>Our Apps</h1>
      <p>Explore our cutting-edge apps designed to help your business succeed!</p>
      {/* Add content related to the apps here */}
    </div>
  );
};

export default AppsPage;
