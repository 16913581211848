import React from 'react';
import { Helmet } from 'react-helmet';
import './Footer.css';

const Footer = () => {
  return (
    <>
      <Helmet>
        <title>Royol Technology</title>
        <meta
          name="description"
          content="Connect with Royol Technology for Web Development, App Development, Networking, and Security Systems. Contact us today!"
        />
      </Helmet>
      <footer>
        <div className="footer-main">
          {/* Services Section */}
          <div className="footer-section">
            <h4>Services</h4>
            <ul>
              <li>
                <a href="#web-development">Web Development</a>
              </li>
              <li>
                <a href="#app-development">App Development</a>
              </li>
              <li>
                <a href="#networking">Networking Solutions</a>
              </li>
              <li>
                <a href="#security">Security Systems</a>
              </li>
            </ul>
          </div>

          {/* Service Areas Section */}
          <div className="footer-section">
            <h4>Service Areas</h4>
            <ul>
              <li>
                <a href="/services/florida/miami">Miami, FL</a>
              </li>
              <li>
                <a href="/services/florida/orlando">Orlando, FL</a>
              </li>
              <li>
                <a href="/services/florida/tampa">Tampa, FL</a>
              </li>
              <li>
                <a href="/services/florida/jacksonville">Jacksonville, FL</a>
              </li>
              <li>
                <a href="/services">View All Locations</a>
              </li>
            </ul>
          </div>

          {/* Contact Section */}
          <div className="footer-section">
            <h4>Contact</h4>
            <p>3900 Haverhill Rd N, #223507, West Palm Beach, FL 33422</p>
            <p>Phone: 239-651-9228</p>
            <p>
              Email:{' '}
              <a href="mailto:info@royoltechnology.com">
                info@royoltechnology.com
              </a>
            </p>
          </div>

          {/* Follow Us Section */}
          <div className="footer-section">
            <h4>Follow Us</h4>
            <div className="social-links">
              <a
                href="https://www.linkedin.com/in/widmison-francois-023185164/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin"></i>
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61550050172621"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/widmison/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="https://github.com/Widmison"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-github"></i>
              </a>
            </div>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="footer-bottom">
          <p>&copy; 2023 Royol Technology LLC. All rights reserved.</p>
          <div className="legal-links">
            <a href="#">Terms of Service</a>
            <a href="#">Privacy Policy</a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;