import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './Services.css';
import webDevImage from '../assets/web-dev.jpg';
import appDevImage from '../assets/App-dev.png';
import ecomImage from '../assets/E-com.webp';

const Services = () => {
  const services = [
    {
      title: 'Web Development',
      description: 'We create responsive and modern websites tailored to your business needs, ensuring seamless performance across all devices.',
      image: webDevImage,
    },
    {
      title: 'App Development',
      description: 'We design and develop mobile applications for iOS and Android platforms, delivering intuitive and feature-rich experiences.',
      image: appDevImage,
    },
    {
      title: 'E-commerce Solutions',
      description: 'Empower your business with scalable and secure e-commerce platforms, integrated with payment gateways and advanced features.',
      image: ecomImage,
    },
    


  ];

  return (
    <HelmetProvider>
      <Helmet>
        <title>Our Services - Royol Technology | Widmison Francois</title>
        <meta name="description" content="Explore our wide range of services, including web development, app development, e-commerce solutions, and more." />
      </Helmet>
      <section id="services-section">
        <h1 className="section-title">Our Services</h1>
        <div className="creative-grid">
          {services.map((service, index) => (
            <div className={`creative-item item-${index + 1}`} key={index}>
              <div className="creative-background" style={{ backgroundImage: `url(${service.image})` }}></div>
              <div className="creative-content">
                <h2 className="creative-title">{service.title}</h2>
                <p className="creative-description">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </HelmetProvider>
  );
};

export default Services;
